import Group from '@/models/Group'

import { each, find, findIndex } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
    groupDetails: {
      id: null,
      name: '',
    },
  },

  mutations: {
    setGroupList(state, groups) {
      each(groups, (group) => {
        const exist = find(state.list, { id: group.id })

        if (!exist) {
          state.list.push(new Group(group))
        }
      })
    },

    setGroupListMeta(state, meta) {
      state.listMeta = meta
    },

    setGroupDetails(state, group) {
      state.groupDetails = group instanceof Group ? group : new Group(group)
    },

    clearGroupList(state) {
      state.list = []
    },

    saveOrUpdateGroup(state, group) {
      const _group = find(state.list, { id: group.id })

      if (_group) {
        return Object.assign(_group, group)
      }

      state.list.push(group)
    },

    removeGroupOnList(state, group) {
      const index = findIndex(state.list, { id: group.id })

      state.list.splice(index, 1)
    },

    clearGroupDetails(state) {
      state.groupDetails = {
        id: null,
        name: '',
      }
    },
  },
}
