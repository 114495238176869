import Vue from 'vue'
import dayjs from 'dayjs'

import { isNumber } from 'lodash'

Vue.filter('formatedDate', function (value, format = 'DD-MM-YYYY') {
  if (!value) return value // eslint-disable-line

  const datetime = isNumber(value) ? value : String(value)

  return dayjs(datetime).format(format)
})
